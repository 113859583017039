import './index.css';
import React, { useState } from 'react';
import axios from 'axios';

const FormularioRegistro = () => {
  const [id, setId] = useState('');
  const [nombre, setNombre] = useState('');
  const [correo, setCorreo] = useState('');
  const [celular, setCelular] = useState('');
  const [mensaje, setMensaje] = useState('');

  const handleIdChange = (event) => {
    setId(event.target.value);
  };

  const handleNombreChange = (event) => {
    setNombre(event.target.value);
  };

  const handleCorreoChange = (event) => {
    setCorreo(event.target.value);
  };

  const handleCelularChange = (event) => {
    setCelular(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!id || !nombre || !correo || !celular) {
      setMensaje('Por favor complete todos los campos.');
      return;
    }

    try {
      const registrationData = {
        identification_number: id,
        name: nombre,
        email: correo,
        mobile: celular,
        token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiZmI4M2Q0ZmZjMjNiMmRjZDhjNjhlZjA1MDNiNDAyMjViMDhlYzZiZDk4ZTk2YzZjYTczMzQ4NjYyNGYyMTZkMWY2OTI1OGI0NWYxYjM1ZTMiLCJpYXQiOjE2OTIwMzc0NDkuNzU2ODAyLCJuYmYiOjE2OTIwMzc0NDkuNzU2ODA2LCJleHAiOjE3MjM2NTk4NDkuNzUzOTM1LCJzdWIiOiI5NTEiLCJzY29wZXMiOltdfQ.ozojXJffSm-uLfn7RuI0Ci88BVYpH0fPvK19B9AKQ87u4vmd5uzX1hFj9UjPKqV0MIvio_-c_YENf0o5mmaosZiTLq0cnQIAU2DlSbf47MIq9lg2sbtyLMJ1O8s3A41oOW8FT1Xx1DPKvjshF1G-',
      };

      const response = await axios.post('https://wallet.kii.global/api/contest/clf', registrationData);
      console.log('API Response:', response.data);

      if (response.data.success) {
        setMensaje('Datos registrados exitosamente.');
        setId('');
        setNombre('');
        setCorreo('');
        setCelular('');
      } else {
        setMensaje('Error al registrar los datos. Por favor intenta nuevamente.');
      }
    } catch (error) {
      setMensaje('Error al registrar los datos. Por favor intenta nuevamente.');
    }
  };

  return (
    <div className='img flex justify-center items-center h-screen'>
      <div className='w-full max-w-xs'>
        <form className='bg-opacity-50 bg-purple-900 rounded-lg shadow-md p-6 w-full max-w-lg' onSubmit={handleSubmit}>
          <div>
            <label className='block uppercase tracking-wide text-white text-xs font-bold mb-2'>ID / No. de pasaporte / Cédula:</label>
            <input className='appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white' type="number" value={id} onChange={handleIdChange} />
          </div>
          <div>
            <label className='block uppercase tracking-wide text-white text-xs font-bold mb-2'>Nombre:</label>
            <input className='appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white' type="text" value={nombre} onChange={handleNombreChange} />
          </div>
          <div>
            <label className='block uppercase tracking-wide text-white text-xs font-bold mb-2'>Correo:</label>
            <input className='appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white' type="email" value={correo} onChange={handleCorreoChange} />
          </div>
          <div>
            <label className='block uppercase tracking-wide text-white text-xs font-bold mb-2'>Celular:</label>
            <input className='appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white' type="tel" value={celular} onChange={handleCelularChange} />
          </div>
          <div className="text-white">
            <label className='block tracking-wide text-white text-sm mb-2'>Al diligenciar este formulario estoy de acuerdo en que mis datos podrán ser usados para crear una cuenta en KII Wallet donde se enviarán las monedas ganadas en el juego</label>  
          </div>
          <br />
          <button className='shadow bg-purple-500 hover:bg-purple-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded' type="submit">Registrarse</button>
        </form>
        {mensaje && <p className="text-white">{mensaje}</p>}
      </div>
    </div>
  );
};

export default FormularioRegistro;
